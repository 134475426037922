.venue {
  background-color: $white;
  position:relative;
  text-align: center;
  background-position: center;
  h2 {
    margin-top: 0;
  }

  .locationMainDetails {
    margin-bottom: 85px;
  }

  .hotelImage {
    width: 100%;
    height: 350px;
    background: url('/assets/images/hotel.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    z-index:2;
    position: relative;
  }

  .accentBlock {
      background-color: $babyPink;
      position:absolute;
      top: -40px;
      left:30px;
      display: inline-block;
      width: 100%;
      height: 350px;
      margin-right: 5px;
      border-radius: 10px;
      z-index: 1;

  }

  .ceremonyImage {
    width: 100%;
    height: 350px;
    background: url('/assets/images/ceremony.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    z-index: 2;
    position: relative;
    background-position: 50% 50%;
  }

  .accentBlock2 {
    background-color: $babyBlue;
    position:absolute;
    bottom: -40px;
    right:30px;
    display: inline-block;
    width: 100%;
    height: 350px;
    margin-right: 5px;
    border-radius: 10px;
    z-index: 1;

  }

  .locationSpacer {
    height: 85px;
  }

  @media (max-width: 767px) {
    .hotelText {
      margin-bottom: 60px;
    }
    .ceremonyText {
      margin-top: 60px;
    }

  }
}