.gifts {
  background-color: $babyBlue;
  position:relative;
  text-align: center;
  background-position: center;
  h2 {
    margin-top: 0;
  }

  .bottomDivider {
    position:absolute;
    bottom: 0;
    transform: rotate(180deg);
    left:0%;
  }
  .bottomDivider2 {
    position:absolute;
    bottom: 0;
    left: 50%;
    transform: rotateX(180deg);
  }
}