.logoSplitContainer {
  position:relative;
  background-image: url('/assets/images/andy-sophie.jpg');
  height:100vh;
  min-height: 650px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  background-position: 45% 45%;

  button {
    border: 2px solid $white;
    background-color: transparent;
    color: $white;
    padding: 20px 40px;
    font-size: 20px;
    cursor: pointer;
    border-radius: 4px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    opacity: .3;
  }

  .pageHeading {
    z-index: 2;
    position: absolute;
    top: 50px;

    text-align: center;
    @media only screen and (min-width : 670px) {
      top: 130px;
    }
    h1, h2 {
      color: $white;
      text-transform: uppercase;
      letter-spacing: 20px;
    }

    .names {
      font-size: 80px;
    }
    .subtitle {
    }
    .date {
      font-size: 50px
    }

    @media (max-width: 767px) {
      .names {
        font-size: 35px;
      }
      .date {
        font-size: 25px;
        margin-bottom: 60px;
      }
    }
  }

  i {
    color: #353536;
    background-color: $white;
    position: absolute;
    bottom: 70px;
    z-index: 2;
    padding: 10px;
    border-radius: 3px;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
    cursor: pointer;
  }


  @media only screen and (min-width : 1000px) {
    svg {
      padding-bottom: 200px;
    }
  }
}

@keyframes bounce {
  0% {transform: translateY(0px);}
  45% {transform: translateY(20px);}
  100% {transform: translateY(0px);}
}