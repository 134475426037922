.footer {
  background-color: $black;
  color: $white;
  p {
    margin: 0;
    margin-bottom: 15px;
    font-size:14px;
    color: $white;
    line-height: 30px;
  }
  .iconContainer {
    text-align: right;
    i {
      color: #6b7480;
      font-size: 29px;
      padding-right: 22px;
      position:relative;
      top: -7px;
    }
    i:hover {
      color: $green;
      cursor: pointer;
    }
  }
  .marginTop {
    margin-top: 20px;
  }

  .firstLink {
    padding-top: 40px;
  }

  .textCenter {
    text-align: center;
  }

  a {
    color: #66A6D5FF;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    font-size: 20px;
    display: block;
    margin-bottom: 15px;
    text-align: center;
  }
}