//media query sizes
$smallDevices: 768px;
$largeDevices: 1200px;

//This will apply anything within to any device that is over what an average phone is.
@mixin tablet {
  @media (min-width: $smallDevices) {
    @content
  }
}

//This will apply anything within to any device that is over what an average tablet is. anything from tablet that is not overwritten with still persist.
@mixin desktop {
  @media (min-width: $largeDevices) {
    @content
  }
}

.modalFullPage {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  .modalCloseButton {
    z-index: 11;
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 30px;
    color: #fff;
    cursor: pointer;
  }


  .modalContainer {
    position: absolute;
    left: 50%;
    width: 100%;
    top: 35px;
    height: 100%;
  }

  .modalContent {
    position: relative;
    left: -50%;
    max-height: 90%;
    min-height: 400px;
    background-color: #fff;
    padding: 30px;
    //@include boxShadow;
    overflow: auto;
    //@include borderRadius;
    &.transparent {
      background-color: transparent;
      box-shadow: none;
    }
  }

  @include tablet {
    .modalCloseButton {
      top: 15px;
      right: 30px;
      font-size: 40px;
    }
    .modalContainer {
      width: 80%;
    }
  }

  @include desktop {
    .modalContainer {
      width: 60%;
    }
  }
}