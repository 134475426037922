$smallDevices: 768px;
$lightGray: #c0c0c0;

@mixin tablet {
  @media (min-width: $smallDevices) {
    @content
  }
}

.fieldContainer {
  width: 100%;
  margin-bottom: 15px;
  display: block;

  input, .emojiAreaContainer, textarea, .reactSelect, .fieldContent, .imageContainer, .textError, .textWarning, .rdt {
    flex: 4;
  }


  label {
    display: block;
    flex: 1;
    font-weight: 400;
    margin-bottom: 5px;
  }

  .reactSelect {
    position: relative;
    left: -5px;
    cursor: pointer;
  }

  @media (min-width: 768px) {
    display: flex;
    label {
      margin-bottom: 0;
      padding-top: 15px;
    }
  }

}

input {
  border: 3px;
  border: 1px solid $lightGray;
  padding: 15px;
  outline: none;
  width: 100%;
}

.errorContainer {
  display: block;
  width: 100%;

  .errorOffset {
    flex: 1;
  }

  .textError {
    flex: 4;
    margin-top: -10px;
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    display: flex;
  }
}

.radioButton {
  display: flex;
  flex-wrap: wrap;
  flex: 4;

  .RadioButtonContainer {
    flex: 1 0 48%;
    @include tablet {
      flex: 1 0 21%;
    }
    margin-right: 8px;
    border-radius: 3px;
    border: 1px solid $lightGray;
    padding: 16px;
    position: relative;

    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* Hide the browser's default checkbox */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 15px;
      right: 16px;
      height: 22px;
      width: 22px;
      background-color: #eee;
      border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
      background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    input:checked ~ .checkmark {
      background-color: #66A6D5FF;
      border-radius: 50%;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
      top: 6px;
      left: 6px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: white;
    }

  }

}