.attendeeData {
  position:relative;
  .removeAttendeeButton {
    padding: 10px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    font-weight: bold;
  }
}

.rsvpPageButton {
  border: 2px solid $black;
  background-color: transparent;
  color: $black;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 3px;
}

.buttonPaddingRsvp {
  margin-bottom: 20px;
}